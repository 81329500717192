<template>
  <div class="container">
    <el-form :inline="true" :model="queryParams" class="form">
      <el-form-item label="供应商名称:">
        <el-select v-model="queryParams.supplierName" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in supplierList"
            :key="item.id"
            :value="item.supplierName"
            :label="item.supplierName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商编号:">
        <el-input v-model="queryParams.supplierNo" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" @click="handleQuery"></el-button>
      </el-form-item>
      <el-form-item style="position: absolute;right:0">
        <el-button class="el-icon-document-add" type="primary" @click="handleAdd">添加供应商</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="supplierName" label="供应商名称"></el-table-column>
        <el-table-column prop="supplierAddress" label="供应商地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplyType" label="供应产品类型"></el-table-column>
        <el-table-column prop="supplierContacts" label="联系人"></el-table-column>
        <el-table-column prop="supplierPhone" label="联系方式"></el-table-column>
        <el-table-column prop="supplierStatus" label="历史审核记录">
          <template slot-scope="scope">
            <div
              style="color:#1361FF;cursor: pointer;"
              @click="handleSee(scope.row)"
            >查看（{{ scope.row.supplierStatus }}）</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button round type="primary" icon="el-icon-my-link" @click="handleLink(scope.row)">信息登记</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[10, 20, 50]"
        :page-size="queryParams.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        class="_Pagination"
        v-show="count"
      ></el-pagination>
    </div>
    <!-- 新增供应商弹框 -->
    <el-dialog title="新增供应商" :visible.sync="dialogVisible" width="478px">
      <el-form ref="form" :model="form" label-width="auto" class="addForm">
        <el-form-item label="供应商名称">
          <el-input v-model="form.supplierName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="供应商编号">
          <el-input v-model="form.supplierNo" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="form.supplierAddress" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="供应产品类型">
          <el-input v-model="form.supplyType" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.supplierContacts" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.supplierPhone" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看审核记录弹框 -->
    <el-dialog title="查看审核记录" :visible.sync="dialogTableVisible" width="60%">
      <el-table
        :data="recordData"
        border
        stripe
        class="table"
        :cell-style="tableBackground"
        :header-row-style="{color:'#000000'}"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="auditTime" label="审核时间" width="150"></el-table-column>
        <el-table-column prop="auditType" label="审核类型" width="150"></el-table-column>
        <el-table-column prop="auditResult" label="审核结果"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "../../api/http.js";
export default {
  data() {
    return {
      supplierList: [],
      elementHeight: 0,
      tableData: [],
      count: 0,
      multipleSelection: [],
      // 查询参数
      queryParams: {
        SupplierName: null,
        SupplierNo: null,
        limit: 10,
        page: 1,
      },
      dialogVisible: false,
      form: {},
      dialogTableVisible: false,
      recordData: [],
    };
  },
  created() {
    this.getSupplierList();
    this.getList();
  },
  mounted() {
    this.getElementHeight();
    let aside = document.querySelector(".aside");
    aside.style.display = "block";
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 56 + 48);
      });
    },
    // 查询供应商信息
    getSupplierList() {
      get("/api/VdaSupplier/GetAll").then((res) => {
        this.supplierList = res.data;
      });
    },
    // 查询供应商列表
    getList() {
      get("/api/VdaSupplier/GetListPage", this.queryParams).then((res) => {
        this.tableData = res.data;
        this.count = res.count;
      });
    },
    // 查询按钮
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    tableBackground(cell) {
      document.getElementsByClassName(cell.column.id)[0].style.background =
        "#E8EBF4";
    },
    // 表单重置
    reset() {
      this.form = {
        id: 0,
        supplierNo: null,
        supplierName: null,
        supplierAddress: null,
        supplyType: null,
        supplierContacts: null,
        supplierPhone: null,
        supplierStatus: 0,
        tenantId: 0,
        saveUserId: 0,
        saveUserNo: null,
        saveUserName: null,
        saveTime: null, 
        isDelete: 0
      };
      this.$nextTick(() =>{
        this.$refs["form"].resetFields();
      })
    },
    // 新增供应商
    handleAdd() {
      this.reset()
      this.dialogVisible = true;
    },
    // 新增供应商提交按钮
    submitForm() {
      post("/api/VdaSupplier", this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.dialogVisible = false;
          this.getList();
        }
      });
    },
    // 查看审核记录
    handleSee(row) {
      console.log(row);
      this.dialogTableVisible = true;
    },
    // 链接按钮
    handleLink(row) {
      this.$router.push({
        path: "/InfoRegister",
        query:{
          data:JSON.stringify(row)
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.queryParams.limit = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .el-form-item {
    margin-bottom: 16px !important;
  }
  ::v-deep .el-form-item__label {
    font-weight: bold;
    color: #3d3d3d;
    padding-right: 6px;
  }
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
.addForm {
  .el-form-item {
    margin-bottom: 10px !important;
    margin-right: 24px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
}
.table {
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #e8e9ebdb !important;
  }
}
::v-deep .el-icon-my-link {
  background: url("../../assets/images/link.png") no-repeat;
  width: 14px;
  height: 14px;
}
::v-deep .el-button.is-round {
  border-radius: 20px !important;
}
</style>